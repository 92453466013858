"use client";

import { assets } from "@koble/assets/assets";
import { useIsMobile } from "@koble/hooks/src/IsMobile";
import { animated, useSpring } from "@react-spring/web";
import { Flex } from "antd";
import Link from "next/link";

import { PublicSwitchHeaderSubTitle } from "@/app/public-switch/common/PublicSwitchHeader/index";
import PublicSwitchHeaderSwitch from "@/app/public-switch/common/PublicSwitchHeader/PublicSwitchHeaderSwitch";
import PublicSwitchHeaderTitle from "@/app/public-switch/common/PublicSwitchHeader/PublicSwitchHeaderTitle";
import PublicSwitchPrimaryButton from "@/app/public-switch/common/PublicSwitchHeader/PublicSwitchPrimaryButton";
import { usePublicSwitchPage } from "@/app/public-switch/PublicSwitchPageHook";
import AnimateOnScroll from "@/common/AnimateOnScroll";
import generatePublicLinearGradient from "@/common/generatePublicLinearGradient";
import config from "@/config";
import es from "@/lang/es";

import styles from "./publicSwitchHeader.module.css";

const PublicSwitchHeaderContent = () => {
  const { isMobile } = useIsMobile();
  const { pageType } = usePublicSwitchPage();

  const { background, buttonBg } = useSpring({
    background: generatePublicLinearGradient({
      colorType: pageType === "userStudent" ? "blue" : "orange",
      angle: 180,
    }),
    buttonBg: generatePublicLinearGradient({
      colorType: pageType === "userStudent" ? "orange" : "blue",
      angle: 90,
    }),
  });

  return (
    <animated.div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background,
      }}
      className={styles.container}
    >
      <div
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "0 16px",
          marginTop: 60,
        }}
      >
        <AnimateOnScroll direction="topToBottom" delay={0} reappear>
          <PublicSwitchHeaderSwitch />
        </AnimateOnScroll>

        <div style={{ height: 30 }} />

        <AnimateOnScroll direction="topToBottom" delay={150} reappear>
          {isMobile ? (
            <PublicSwitchHeaderTitle fontSize={25} lineHeight={38} />
          ) : (
            <PublicSwitchHeaderTitle fontSize={40} lineHeight={48} />
          )}
        </AnimateOnScroll>

        <div style={{ height: 10 }} />

        <AnimateOnScroll reappear direction="topToBottom" delay={300}>
          {isMobile ? (
            <PublicSwitchHeaderSubTitle fontSize={16} lineHeight={26} />
          ) : (
            <PublicSwitchHeaderSubTitle fontSize={18} lineHeight={26} />
          )}
        </AnimateOnScroll>

        <div style={{ height: 25 }} />

        <Flex
          wrap="wrap"
          justify="center"
          style={{ columnGap: 16, rowGap: 16 }}
        >
          <AnimateOnScroll direction="topToBottom" delay={150} reappear>
            <Link
              href={
                pageType === "userStudent"
                  ? `/empleos`
                  : `${config.NEXT_PUBLIC_SSO_FRONTEND}/sign-up/user-recruiter`
              }
            >
              <PublicSwitchPrimaryButton
                label={
                  pageType === "userStudent"
                    ? es.switch.header.content.button.userStudent
                    : es.switch.header.content.button.userRecruiter
                }
                size="large"
                color="white"
                background={buttonBg}
                style={{
                  border: "none",
                }}
              />
            </Link>
          </AnimateOnScroll>

          <AnimateOnScroll direction="topToBottom" delay={150} reappear>
            <a
              href={
                pageType === "userStudent"
                  ? es.switch.header.content.buttonDemoHref.userStudent
                  : es.switch.header.content.buttonDemoHref.userRecruiter
              }
              target="_blank"
              rel="noreferrer"
            >
              <PublicSwitchPrimaryButton
                label={
                  pageType === "userStudent"
                    ? es.switch.header.content.buttonDemo.userStudent
                    : es.switch.header.content.buttonDemo.userRecruiter
                }
                size="large"
                color="white"
                background="transparent"
              />
            </a>
          </AnimateOnScroll>
        </Flex>

        <div style={{ height: 40 }} />

        <AnimateOnScroll direction="topToBottom" delay={600} reappear>
          <img
            src={
              pageType === "userStudent"
                ? assets.PUBLIC_USER_STUDENT_HEADER_IMAGE
                : assets.PUBLIC_USER_RECRUITER_HEADER_IMAGE
            }
            alt="user-student-header"
            style={{ maxWidth: 900, width: "100%" }}
          />
        </AnimateOnScroll>
      </div>
    </animated.div>
  );
};

export default PublicSwitchHeaderContent;
