"use client";

import React from "react";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { assets } from "@koble/assets/assets";
import { useAuth } from "@koble/hooks";
import { animated, useSpring } from "@react-spring/web";
import { Flex, Space, Typography } from "antd";
import Link from "next/link";

import { usePublicSwitchPage } from "@/app/public-switch/PublicSwitchPageHook";
import generatePublicLinearGradient from "@/common/generatePublicLinearGradient";
import {
  FacebookLogo,
  InstagramLogo,
  LinkedinLogo,
  TiktokLogo,
} from "@/common/logos/logos";
import {
  PUBLIC_BLUE_GRADIENT_TO_COLOR,
  PUBLIC_ORANGE_GRADIENT_TO_COLOR,
} from "@/common/publicColors";
import config from "@/config";
import es from "@/lang/es";

const GhostButton = ({ active, label }: { active: boolean; label: string }) => (
  <button
    style={{
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      padding: "8px 0",
    }}
  >
    <Typography.Text
      style={{
        fontSize: 28,
        opacity: active ? 1 : 0.6,
        fontWeight: 400,
      }}
    >
      {label}
    </Typography.Text>
  </button>
);

const PublicSwitchHeaderNavBarMobile = () => {
  const { pageType } = usePublicSwitchPage();
  const { identityClaims } = useAuth();

  const [open, setOpen] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);

  const { buttonBg, buttonBc, buttonColor } = useSpring({
    buttonBg: generatePublicLinearGradient({
      colorType: pageType === "userStudent" ? "orange" : "blue",
      angle: 90,
    }),
    buttonBc:
      pageType === "userStudent"
        ? PUBLIC_ORANGE_GRADIENT_TO_COLOR
        : PUBLIC_BLUE_GRADIENT_TO_COLOR,
    buttonColor:
      pageType === "userStudent"
        ? PUBLIC_ORANGE_GRADIENT_TO_COLOR
        : PUBLIC_BLUE_GRADIENT_TO_COLOR,
  });

  const fade = useSpring({
    opacity: isVisible ? 1 : 0,
  });

  return (
    <>
      <MenuOutlined
        onClick={() => {
          setOpen(true);
          setIsVisible(true);
        }}
        style={{ color: "white", fontSize: 20 }}
      />
      {open && (
        <animated.div
          style={{
            position: "absolute",
            background: "white",
            width: "100vw",
            height: "100vh",
            padding: "14px 16px 16px",
            top: 0,
            left: 0,
            ...fade,
          }}
        >
          <Flex align="center" justify="space-between">
            <Link href="/" title={es.switch.header.navbar.logoTitle}>
              <img
                src={assets.KOBLE_LOGO_BLACK_WITH_TEXT}
                alt={es.switch.header.navbar.logoAlt}
              />
            </Link>
            <CloseOutlined
              onClick={async () => {
                setIsVisible(false);
                await new Promise((resolve) => setTimeout(resolve, 500));
                setOpen(false);
              }}
              style={{ fontSize: 20 }}
            />
          </Flex>

          <div style={{ height: 30 }} />

          <Flex vertical>
            <Link
              href={es.switch.metadata.userStudentSlug}
              title={es.switch.header.navbar.userStudentTitle}
              onClick={async () => {
                setIsVisible(false);
                await new Promise((resolve) => setTimeout(resolve, 500));
                setOpen(false);
              }}
            >
              <GhostButton
                active={pageType === "userStudent"}
                label={es.switch.header.navbar.userStudent}
              />
            </Link>
            <Link
              href={es.switch.metadata.userRecruiterSlug}
              title={es.switch.header.navbar.userRecruiterTitle}
              onClick={async () => {
                setIsVisible(false);
                await new Promise((resolve) => setTimeout(resolve, 500));
                setOpen(false);
              }}
            >
              <GhostButton
                active={pageType === "userRecruiter"}
                label={es.switch.header.navbar.userRecruiter}
              />
            </Link>
            {/* <Link href="/blog" title={es.switch.header.navbar.blogTitle}> */}
            {/*  <GhostButton */}
            {/*    active={false} */}
            {/*    label={es.switch.header.navbar.blog} */}
            {/*  /> */}
            {/* </Link> */}
          </Flex>

          <div style={{ height: 50 }} />

          <Flex vertical gap={20}>
            {identityClaims ? (
              <a
                href={
                  pageType === "userStudent"
                    ? config.NEXT_PUBLIC_USER_STUDENT_FRONTEND
                    : config.NEXT_PUBLIC_USER_RECRUITER_FRONTEND
                }
              >
                <animated.button
                  style={{
                    border: "none",
                    cursor: "pointer",
                    padding: "8px 16px",
                    borderRadius: 50,
                    width: "fit-content",
                    background: buttonBg,
                  }}
                >
                  <Typography.Text
                    style={{
                      color: "white",
                      fontWeight: 600,
                      fontSize: 14,
                    }}
                  >
                    {es.switch.header.navbar.goToApp}
                  </Typography.Text>
                </animated.button>
              </a>
            ) : (
              <>
                <a href={config.NEXT_PUBLIC_SSO_FRONTEND}>
                  <animated.button
                    style={{
                      cursor: "pointer",
                      padding: "7px 16px",
                      borderRadius: 50,
                      width: "fit-content",
                      background: "transparent",
                      border: "1px solid",
                      borderColor: buttonBc,
                    }}
                  >
                    <animated.span style={{ color: buttonColor }}>
                      <Typography.Text
                        style={{
                          color: "inherit",
                          fontWeight: 600,
                          fontSize: 14,
                        }}
                      >
                        {es.switch.header.navbar.signIn}
                      </Typography.Text>
                    </animated.span>
                  </animated.button>
                </a>

                <a
                  href={
                    pageType === "userStudent"
                      ? `${config.NEXT_PUBLIC_SSO_FRONTEND}/sign-up/user-student`
                      : `${config.NEXT_PUBLIC_SSO_FRONTEND}/sign-up/user-recruiter`
                  }
                >
                  <animated.button
                    style={{
                      border: "none",
                      cursor: "pointer",
                      padding: "8px 16px",
                      borderRadius: 50,
                      width: "fit-content",
                      background: buttonBg,
                    }}
                  >
                    <Typography.Text
                      style={{
                        color: "white",
                        fontWeight: 600,
                        fontSize: 14,
                      }}
                    >
                      {es.switch.header.navbar.signUp}
                    </Typography.Text>
                  </animated.button>
                </a>
              </>
            )}
          </Flex>

          <Space size={25} style={{ position: "fixed", bottom: 16 }}>
            <a
              href={es.switch.footer.socialMedia.instagramLink}
              target="_blank"
              rel="noreferrer"
            >
              <InstagramLogo color="black" />
            </a>
            <a
              href={es.switch.footer.socialMedia.tikTokLink}
              target="_blank"
              rel="noreferrer"
            >
              <TiktokLogo color="black" />
            </a>
            <a
              href={es.switch.footer.socialMedia.linkedinLink}
              target="_blank"
              rel="noreferrer"
            >
              <LinkedinLogo color="black" />
            </a>
            <a
              href={es.switch.footer.socialMedia.facebookLink}
              target="_blank"
              rel="noreferrer"
            >
              <FacebookLogo color="black" />
            </a>
          </Space>
        </animated.div>
      )}
    </>
  );
};

export default PublicSwitchHeaderNavBarMobile;
